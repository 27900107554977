import { Grid } from "@mui/material";
import React, { useState } from "react";

const lightboxStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "black",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1000,
};

const lightboxImageStyles = {
  maxHeight: "80%",
  maxWidth: "90%", 
  marginBottom: "20px",
};

const arrowButtonStyles = {
  cursor: "pointer",
  color: "#fff",
  fontSize: "2rem",
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  background: "none",
  border: "none",
};

const Lightbox = ({
  images,
  currentIndex,
  onClose,
  onPrev,
  onNext,
}) => {
  return (
    <Grid style={lightboxStyles} onClick={onClose}>
      <button style={{ ...arrowButtonStyles, left: "20px" }} onClick={onPrev}>
        &lt;
      </button>
      <img
        src={images[currentIndex].src}
        alt={images[currentIndex].title}
        style={lightboxImageStyles}
        onClick={(e) => e.stopPropagation()}
      />
      <button style={{ ...arrowButtonStyles, right: "20px" }} onClick={onNext}>
        &gt;
      </button>
    </Grid>
  );
};

const ImageGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openLightbox = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const goToNext = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const goToPrev = (e) => {
    e.stopPropagation();
    setCurrentIndex((currentIndex + images.length - 1) % images.length);
  };

  return (
    <Grid container spacing={2} sm={12} md={12}>
      <Grid item xs={12}>
        <h2 style={{ textAlign: 'center' }}>Image Gallery</h2>
      </Grid>
      <Grid container spacing={1} justifyContent="center" style={{ flexWrap: 'wrap' }}>
        {images.map((image, index) => (
          <Grid item key={index} xs={4} sm={3} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={image.src}
              alt={image.title}
              style={{ width: "100%", height: "auto", cursor: "pointer" }} 
              onClick={() => openLightbox(index)}
            />
          </Grid>
        ))}
      </Grid>

      {isOpen && (
        <Lightbox
          images={images}
          currentIndex={currentIndex}
          onClose={closeLightbox}
          onPrev={goToPrev}
          onNext={goToNext}
        />
      )}
    </Grid>
  );
};

export default ImageGallery;