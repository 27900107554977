import React, { createContext, useState, useContext } from "react";

const UserContext = createContext(null);

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {

  const [user, setUser] = useState(null);

  const updateUser = (newUser) => {
    setUser(newUser); 
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};
