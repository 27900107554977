import { useState } from "react";
import AlertDialog from "./AlertDialog";
import { Button, Grid } from "@mui/material";

function Dialogcontent() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const options = [
    { name: " Agree", func: handleClose },
    { name: "Disagree", func: handleClose, color: "red" },
  ];

  return (
    <Grid item xs={12}>
      <Grid>
        <Button variant="outlined" onClick={handleOpen}>
          Alert dialog With Buttons
        </Button>
        <AlertDialog
          content={"Alert Dialog is used with diffrent Buttons"}
          title={"ByteCrafts Alert Dialog Title"}
          options={options}
          open={open}
          handleClose={handleClose}
          dialogClose={handleClose}
        />
      </Grid>
    </Grid>
  );
}
export default Dialogcontent;
