import React from 'react';
import ImageGallery from './ImageGallery';

const ByteCraftsGallery = () => {
  const imageArray = [
    {
      title: 'Sunset',
      description: 'A beautiful sunset over the mountains.',
      src: 'Sunset.png',
    },
    {
      title: 'Beach',
      description: 'A serene view of the beach with clear blue water.',
      src: 'Beach.jpg',
    },
    {
      title: 'Forest',
      description: 'A lush green forest in the morning mist.',
      src: 'Forest.jpg',
    },
    {
      title: 'City',
      description: 'The bustling city life at night with bright lights.',
      src: 'City.jpg',
    },
  ];

  return (
    <div>
      <ImageGallery images={imageArray} />
    </div>
  );
};

export default ByteCraftsGallery;
