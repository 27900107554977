import React from "react";
import styled, { keyframes } from "styled-components";

// Define the keyframes for the hue rotation animation
const rotateHue = keyframes`
  to {
    filter: hue-rotate(1turn);
  }
`;

// Create a styled button component
const StyledButton = styled.button`
  --border-radius: 15px;
  --border-width: 4px;
  appearance: none;
  position: relative;
  padding: 1em 2em;
  border: 0;
  background-color: #212121;
  font-family: "Roboto", Arial, "Segoe UI", sans-serif;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  z-index: 2;
  box-sizing: border-box;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: var(--border-width);
    border-radius: var(--border-radius);
    background-image: conic-gradient(
      #488cfb,
      #29dbbc,
      #ddf505,
      #ff9f0e,
      #e440bb,
      #655adc,
      #488cfb
    );

    -webkit-mask: linear-gradient(#000, #000) content-box, linear-gradient(#000, #000);
    mask: linear-gradient(#000, #000) content-box, linear-gradient(#000, #000);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    box-sizing: border-box;
    filter: hue-rotate(0);
    animation: ${rotateHue} linear 500ms infinite;
    animation-play-state: paused;
  }

  &:hover::after {
    animation-play-state: running;
  }

  &:active {
    --border-width: 5px;
    
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    padding: var(--border-width);
    z-index: 1;
  }
`;

// Create the button component
const HoverButton = () => {
  return <StyledButton>Hover me</StyledButton>;
};

const App = () => {
  return (
    <div>
      <HoverButton />
    </div>
  );
};

export default App;