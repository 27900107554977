import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchComponent = ({ data, setFilteredData, searchKey }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = data.filter((item) =>
      item[searchKey].toLowerCase().includes(value)
    );
    setFilteredData(filtered);
  };

  return (
    <TextField
      variant="outlined"
      value={searchTerm}
      onChange={handleSearch}
      placeholder={`Search....`}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        style: {
          borderRadius: '20px',
          backgroundColor: '#f0f0f0', 
          height:"40px",
          width:"280px"
        },
      }}
    
    />
  );
};

export default SearchComponent;
