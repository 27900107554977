import React from "react";
import Button2 from "./Buttons2";
import Button3 from "./Button3";
import GoldenButton from "./GoldenButton";
import Button4 from "./Button4";
import PlayButton from "./PlayButton";
import Hover1 from "./Hover1";
import Hover2 from "./HoverMe2";
import Hover3 from "./HoverMe3";
import Hover4 from "./HoverMe44";
import Hover5 from "./HovverMe5";
import Hover6 from "./HoverMe6";
function Button1() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "16px",
        flexWrap: "wrap",
      }}
    >
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Button2 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Button3 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <GoldenButton />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Button4 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <PlayButton />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Hover1 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Hover2 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Hover3 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Hover4 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "40%" }}>
        <Hover5 />
      </div>
      <div style={{ flex: "1 0 20%", maxWidth: "20%" }}>
        <Hover6 />
      </div>
   
    </div>
  );
}
export default Button1;
