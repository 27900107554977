import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
} from "@mui/material";
import React from "react";

export default function LCard({ options, images }) {
  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          paddingTop: "60px",
        }}
      >
        {images.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{ margin: "40px" }}>
              <CardMedia
                component="img"
                style={{ width: "100%", height: "300px" }}
                image={card.image}
              />
              <CardContent>
                <Grid container spacing={1} style={{ alignItems: "center" }}>
                  <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Typography style={{ fontSize: "25px" }}>
                      {card.title}
                    </Typography>
                    <Typography style={{ paddingTop: "10px" }}>
                      {card.info}
                    </Typography>
                  </Grid>
                  <Grid style={{ paddingTop: "20px" }}>
                    {options.map((option, index) => (
                      <Button key={index}>{option.name}</Button>
                    ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
}
