import HomePage from "./Views/HomePage";
import AboutPage from "./Views/AboutPage";
import AlertDialog from "./ReactComponents/Alert/BytecraftsAlertDialog";
import BytecraftsSnackbar from "./ReactComponents/Snackbar/BytecraftsSnackbar";
import ByteCraftsDrawer from "./ReactComponents/Drawer/ByteCraftsDrawer";
import LCard from "./ReactComponents/Card/ByteCraftsLCard";
import TextFormValidator from "./ReactComponents/ValidatornForm/TextFormValidator";
import ByteCraftsTable from "./ReactComponents/Table/ByteCraftsTable";
import ByteCraftsStepper from "./ReactComponents/Stepper/ByteCraftsStepper";
import ByteCraftsSearchComponent from "./ReactComponents/Search/ByteCraftsSearchComponent";
import ByteCraftsNavbar from "./ReactComponents/Navbar/ByteCraftsNavbar";
import ByteCraftsGallery from "./ReactComponents/Gallery/ByteCraftsGallery";
import Buttons from "./ReactComponents/Buttons/Button1";

const routes = [
  {
    path: "/",
    element: <HomePage />,
    label: "Home",
  },
  {
    path: "/about",
    element: <AboutPage />,
    label: "About",
  },
  {
    path: "/alert",
    element: <AlertDialog />,
    label: "AlertDialog",
  },
  {
    path: "/SimpleSnackbar",
    element: <BytecraftsSnackbar />,
    label: "Snackbar",
  },
  {
    path: "/Drawer",
    element: <ByteCraftsDrawer />,
    label: "Drawer",
  },
  {
    path: "/LCard",
    element: <LCard />,
    label: "Card",
  },
  {
    path: "/validationform",
    element: <TextFormValidator />,
    label: "Form Validation",
  },
  {
    path: "/table",
    element: <ByteCraftsTable />,
    label: "DessertTable",
  },
  {
    path: "/VerticalStepper",
    element: <ByteCraftsStepper />,
    label: "Stepper",
  },
  {
    path: "/search",
    element: <ByteCraftsSearchComponent />,
    label: "Search",
  },
  {
    path: "/navbar",
    element: <ByteCraftsNavbar />,
    label: "Navbar",
  },
  {
    path: "/gallery",
    element: <ByteCraftsGallery />,
    label: "Gallery",
  },
  {
    path: "/buttons",
    element: <Buttons />,
    label: "Buttons",
  },
];

export default routes;
