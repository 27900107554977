import {
    Box,
    Button,
    Grid,
    Paper,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
    styled,
    Typography,
    Step,
  } from "@mui/material";
  import React, { useState } from "react";
  
  export default function VerticalStepper({ steps }) {
    const [activeStep, setActiveStep] = useState(0);
  
    const handleNext = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
      setActiveStep(0);
    };
  
    return (
      <Box>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  index === 3 ? <Typography variant="caption">Last Step</Typography> : null
                }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
                <Box>
                  <Grid>
                    <Button onClick={handleNext}>
                      {index === steps.length - 1 ? "Finish" : "Continue"}
                    </Button>
                    <Button onClick={handleBack} disabled={index === 0}>
                      Back
                    </Button>
                  </Grid>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length-1 && (
          <Paper>
            <Typography>All steps completed - you're finished</Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Paper>
        )}
      </Box>
    );
  }