import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

function AlertDialog({
  title,
  content,
  open,
  handleClose,
  dialogClose,
  options,
}) {
  return (
    <div style={{ marginTop: "40vh" }}>
      <Dialog open={open} onClose={dialogClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          {options.map((item, index) => (
            <Button
              key={index}
              style={{ color: item?.color ? item?.color : null }}
              onClick={item?.func}
            >
              {item?.name}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AlertDialog;
