import React from 'react';
import { Typography, Card, CardActionArea, CardContent, CardMedia, Grid } from '@mui/material';

const HomePage = () => {
  const cases = [
    { id: 1, title: '3D tunnel', url: 'codeGrid/3dTunnel/tunnel.html', image: 'https://via.placeholder.com/150' },
    { id: 2, title: 'We Go Again', url: 'codeGrid/weGoAgain/index.html', image: 'https://via.placeholder.com/150' },
    { id: 3, title: 'Images 3D tunnel', url: 'codeGrid/3dTunnel-v2/index.html', image: 'https://via.placeholder.com/150' },
    { id: 4, title: 'Akaru Menu', url: 'codeGrid/akaru_menu/index.html', image: 'https://via.placeholder.com/150' },
    { id: 5, title: 'Genisis Scroll', url: 'codeGrid/zentry_scroll/index.html', image: 'https://via.placeholder.com/150' },
    { id: 6, title: 'Carousel Slider', url: 'codeGrid/camille_slider/index.html', image: 'https://via.placeholder.com/150' },
    { id: 7, title: 'Sliding Filter', url: 'codeGrid/filters/index.html', image: 'https://via.placeholder.com/150' },
    { id: 8, title: 'Landing Page Loader', url: 'codeGrid/landingpage_reveal/index.html', image: 'https://via.placeholder.com/150' },
    { id: 9, title: 'Text Hover', url: 'codeGrid/text_hover/index.html', image: 'https://via.placeholder.com/150' },
    { id: 10, title: 'Shader Distortion', url: 'codeGrid/shader_distortion/index.html', image: 'https://via.placeholder.com/150' },
    { id: 11, title: 'Calender Details', url: 'codeGrid/responsive_card/index.html', image: 'https://via.placeholder.com/150' },
    { id: 12, title: 'Landing Page Gravity ', url: 'codeGrid/gravity_landingPage/index.html', image: 'https://via.placeholder.com/150' },
    { id: 13, title: 'Hypnotic Gallery', url: 'codeGrid/hypnotic_gallery/index.html', image: 'https://via.placeholder.com/150' },
    { id: 14, title: 'Infinite Scrolling', url: 'codeGrid/infinite_scrolling/index.html', image: 'https://via.placeholder.com/150' },
    { id: 15, title: 'Instagram Stories', url: 'codeGrid/stories/index.html', image: 'https://via.placeholder.com/150' },
    { id: 16, title: 'Mask Reveal', url: 'codeGrid/mask_reveal/index.html', image: 'https://via.placeholder.com/150' },
    { id: 17, title: 'Slider', url: 'codeGrid/slider/index.html', image: 'https://via.placeholder.com/150' },
    { id: 18, title: 'Slider trigger', url: 'codeGrid/slider_2/index.html', image: 'https://via.placeholder.com/150' },
    { id: 18, title: 'Scroll trigger', url: 'codeGrid/scroll_trigger/index.html', image: 'https://via.placeholder.com/150' },

    // Add more cases as needed
  ];
  const handleOpenHtmlPage = () => {
    window.open('codeGrid/3dTunnel/tunnel.html', '_blank');
  };
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Css Grid Template
      </Typography>
      <Grid container spacing={2}>
        {cases.map((caseItem) => (
          <Grid item xs={12} sm={6} md={4} key={caseItem.id}>
            <Card>
              <CardActionArea
                onClick={() => window.open(caseItem.url, '_blank')}
              >
                {/* Image for the card */}
                <CardMedia
                  component="img"
                  height="140"
                  image={caseItem.image}
                  alt={caseItem.title}
                />
                <CardContent>
                  {/* Title for the card */}
                  <Typography variant="h6" align="center">
                    {caseItem.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default HomePage;