import React from "react";

function Button1() {
  return (
    <div>
      <button
        style={{
          alignItems: "center",
          backgroundImage:
            "linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb)",
          border: 0,
          borderRadius: "8px",
          boxShadow: "rgba(151, 65, 252, 0.2) 0 15px 30px -5px",
          boxSizing: "border-box",
          color: "#ffffff",
          display: "flex",
          fontSize: "18px",
          justifyContent: "center",
          lineHeight: "1em",
          maxWidth: "100%",
          minWidth: "140px",
          padding: "3px",
          textDecoration: "none",
          userSelect: "none",
          WebkitUserSelect: "none",
          touchAction: "manipulation",
          whiteSpace: "nowrap",
          cursor: "pointer",
          transition: "all 0.3s",
          outline: 0,
        }}
        onMouseOver={(e) =>
          (e.currentTarget.children[0].style.background = "none")
        }
        onMouseOut={(e) =>
          (e.currentTarget.children[0].style.background = "rgb(5, 6, 45)")
        }
        onMouseDown={(e) => (e.currentTarget.style.transform = "scale(0.9)")}
        onMouseUp={(e) => (e.currentTarget.style.transform = "scale(1)")}
      >
        <span
          style={{
            backgroundColor: "rgb(5, 6, 45)",
            padding: "16px 24px",
            borderRadius: "6px",
            width: "100%",
            height: "100%",
            transition: "300ms",
          }}
        >
          Button
        </span>
      </button>
    </div>
  );
}

export default Button1;
