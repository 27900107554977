import React from "react";
import VerticalStepper from "./VerticalStepper";
import { Grid } from "@mui/material";

export default function ParentComponent() {
  const steps = [
    {
      label: "Step 1",
      description: "This is the first step",
    },
    {
      label: "Step 2",
      description: "This is the second step",
    },
    {
      label: "Step 3",
      description: "This is the third step",
    },
    {
      label: "Step 4",
      description: "This is the forth step",
    },
  ];

  return (
    <Grid>
      <VerticalStepper steps={steps} />
    </Grid>
  );
}
