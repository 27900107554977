import React from "react";
import styled, { keyframes } from "styled-components";

const squeezeAnimation = keyframes`
  0% {
    transform: scale3d(1, 1, 1);
  }
  30% {
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
`;

const SqueezeButtonStyled = styled.button`
  height: 2.8em;
  width: 9em;
  background: transparent;
  animation: jello-horizontal 0.9s both;
  border: 2px solid #016dd9;
  outline: none;
  color: #016dd9;
  cursor: pointer;
  font-size: 17px;

  &:hover {
    background: #016dd9;
    color: #ffffff;
    animation: ${squeezeAnimation} 0.9s both;
  }
`;

const SqueezeButton = () => {
  return <SqueezeButtonStyled>Button 3</SqueezeButtonStyled>;
};

export default SqueezeButton;
