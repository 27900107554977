import React from "react";
import styled from "styled-components";

// Styled button component
const AnimatedButton = styled.button`
  position: relative;
  display: inline-block;
  padding: 12px 24px;
  border: none;
  font-size: 16px;
  background-color: inherit;
  border-radius: 100px;
  font-weight: 600;
  color: black; /* Change text color to black by default */
  box-shadow: 0 0 0 2px #ffffff20;
  cursor: pointer;
  overflow: hidden;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1);

  &:hover {
    box-shadow: 0 0 0 5px #2195f360;
    color: #ffffff; /* Change color to white on hover */

    span:last-child {
      width: 150px;
      height: 150px;
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.95);
  }
`;

const ButtonSpan = styled.span`
  position: relative;
  z-index: 1;

  &:last-child {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-color: #2196f3;
    border-radius: 50%;
    opacity: 0;
    transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  }
`;

// Main component
const AnimatedButtonComponent = () => {
  return (
    <AnimatedButton className="animated-button">
      <ButtonSpan>Hover me44</ButtonSpan>
      <ButtonSpan></ButtonSpan>
    </AnimatedButton>
  );
};

export default AnimatedButtonComponent;
