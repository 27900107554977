  import React from "react";
  import Drawer from "./Drawer";
  import MailIcon from "@mui/icons-material/Mail";
  import InboxIcon from "@mui/icons-material/Inbox";

  function App() {
    const items = [
      {
        text: "All mail",
        icon: <InboxIcon />,
        onClick: () => console.log("All mail clicked"),
        color: "blue",
      },
      {
        text: "Trash",
        icon: <MailIcon />,
        onClick: () => console.log("Trash clicked"),
        color: "red",
      },
      {
        text: "Spam",
        icon: <MailIcon />,
        onClick: () => console.log("Spam clicked"),
        color: "green",
      },
    ];

    return (
      <div>
        <Drawer items={items} anchor="left" />
      </div>
    );
  }

  export default App;
