import { useState } from "react";
import SimpleSnackbar from "./SimpleSnackbar";
import { Button } from "@mui/material";

export default function Message() {
  const [open, setOpen] = useState(false);

  const handleClick = () => setOpen(true);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClick}>Open Snackbar</Button>
      <SimpleSnackbar
        open={open}
        handleClose={handleClose}
        message="SnackBar Message"
        autoHideDuration={3000}
        backgroundColor="#333"
        actionButton="undo"
      />
    </div>
  );
}
