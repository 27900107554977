import React, { useState } from "react";
import SearchComponent from "./SearchComponent";

const ByteCraftsSearchComponent = () => {
  const data = [
    { id: 1, name: "John Doe", age: "26" },
    { id: 2, name: "Jon Smith", age: "40" },
    { id: 3, name: "Emily", age: "23" },
  ];

  const [filteredData, setFilteredData] = useState(data);

  return (
    <div>
      <SearchComponent
        data={data}
        setFilteredData={setFilteredData}
        searchKey="name"
      />
      <div>
        {filteredData.map((item) => (
          <div key={item.id}>
            {item.id}. {item.name} ,age={item.age}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ByteCraftsSearchComponent;
