import React from 'react';
import { Drawer, List, ListItem, ListItemText, IconButton, Divider, AppBar, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Outlet, Link } from 'react-router-dom';
import routes from './navigationConfig';

const drawerWidth = 240;

const DrawerPage = ({ drawerOpen, handleDrawerClose, handleDrawerOpen }) => {
  return (
    <div style={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          transition: (theme) => theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          ...(drawerOpen && {
            marginLeft: drawerWidth,
            width: `calc(100% - ${drawerWidth}px)`,
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          }),
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(drawerOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            My Application
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={drawerOpen}
      >
        <IconButton onClick={handleDrawerClose} edge="start" sx={{ m: 2 }}>
          <ChevronLeftIcon />
        </IconButton>
        <Divider />
        <List>
          {routes.map((route, index) => (
            <ListItem button component={Link} to={route.path} key={index} onClick={handleDrawerClose}>
              <ListItemText primary={route.label} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      <main style={{
        flexGrow: 1,
        padding: '16px',
        marginLeft: drawerOpen ? `${drawerWidth}px` : 0,
        transition: 'margin-left 0.3s ease',
      }}>
        <Toolbar />
        <Outlet />
      </main>
    </div>
  );
};

export default DrawerPage;
