import React from 'react';
import Navbar from './Navbar';

const ByteCraftsNavbar = () => {
  const navItems = [
    {
      title: 'Home',
      link: '/',
    },
    {
      title: 'About',
      link: '/about',
      subsections: [
        {
          title: 'Team',
          link: '/about/team',
        },
        {
          title: 'Company',
          link: '/about/company',
          subsections: [
            {
              title: 'History',
              link: '/about/company/history',
            },
            {
              title: 'Vision',
              link: '/about/company/vision',
            },
          ],
        },
      ],
    },
    {
      title: 'Services',
      link: '/services',
    },
    {
      title: 'Contact',
      link: '/contact',
    },
  ];

  return (
    <div>
      <Navbar items={navItems} color="#005f73" /> 
    </div>
  );
};

export default ByteCraftsNavbar;
