import React from "react";
import LizardCard from "./LCard";

export default function LizardButton() {
  const options = [
    { name: "SHARE" },
    { name: "LEARN MORE" },
    { name: "Cancel" },
  ];
  const images = [
    {
      image: "Lizard.jpg",
      title: "Lizard",
      info: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
    },
    {
      image: "Lizard.jpg",
      title: "Lizard",
      info: "Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging across all continents except Antarctica",
    },
  ];
  return <LizardCard options={options} images={images} />;
}
