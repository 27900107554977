import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

export default function SimpleSnackbar({
  open,
  handleClose,
  message,
  autoHideDuration,
  backgroundColor,
  actionButton,
}) {
  const action = (
    <>
      {actionButton === "undo" && <Button onClick={handleClose}>UNDO</Button>}
      {actionButton === "ok" && <Button onClick={handleClose}>OK</Button>}
      {actionButton === "close" && (
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      )}
    </>
  );

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      message={message}
      action={action}
      sx={{
        "& .MuiSnackbarContent-root": {
          backgroundColor: backgroundColor || "inherit", // Use "inherit" or a specific fallback color
        },
      }}
    />
  );
}
