import React from "react";
import styled, { keyframes } from "styled-components";

const glitch = keyframes`
  0% { clip-path: inset(80% -6px 0 0); transform: translate(-20px, -10px); }
  10% { clip-path: inset(10% -6px 85% 0); transform: translate(10px, 10px); }
  20% { clip-path: inset(80% -6px 0 0); transform: translate(-10px, 10px); }
  30% { clip-path: inset(10% -6px 85% 0); transform: translate(0px, 5px); }
  40% { clip-path: inset(50% -6px 30% 0); transform: translate(-5px, 0px); }
  50% { clip-path: inset(10% -6px 85% 0); transform: translate(5px, 0px); }
  60% { clip-path: inset(40% -6px 43% 0); transform: translate(5px, 10px); }
  70% { clip-path: inset(50% -6px 30% 0); transform: translate(-10px, 10px); }
  80% { clip-path: inset(80% -6px 5% 0); transform: translate(20px, -10px); }
  90% { clip-path: inset(80% -6px 0 0); transform: translate(-10px, 0px); }
  100% { clip-path: inset(80% -6px 0 0); transform: translate(0); }
`;

const StyledButton = styled.button`
  padding: 16px 20px;
  font-size: 18px;
  background: linear-gradient(45deg, transparent 5%, #ff013c 5%);
  border: 0;
  color: #fff;
  letter-spacing: 3px;
  line-height: 1;
  box-shadow: 6px 0px 0px #00e6f6;
  outline: transparent;
  position: relative;
  cursor: pointer;

  &::after {
    --slice-0: inset(50% 50% 50% 50%);
    content: "HOVER ME";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      45deg,
      transparent 3%,
      #00e6f6 3%,
      #00e6f6 5%,
      #ff013c 5%
    );
    text-shadow: -3px -3px 0px #f8f005, 3px 3px 0px #00e6f6;
    clip-path: var(--slice-0);
  }

  &:hover::after {
    animation: ${glitch} 1s steps(2, end);
  }
`;

const App = () => {
  return (
    <div>
      <StyledButton>HOVER ME</StyledButton>
    </div>
  );
};

export default App;
