import { Dialog, Grid, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

// Styled TextField
const TextField = styled(TextValidator)(() => ({
  width: "100%",
  marginBottom: "16px",
}));

function ValidatorFormDialog({ open, setOpen, handleSubmit, object }) {
  const errorMessages = {
    required: "This Field Is Required",
    isemail: "Enter Proper Mail",
    isAadhar: "Please Enter Valid Addhar Id",
  };
  function getErrorMessages(validators) {
    if (!validators || !Array.isArray(validators)) {
      return [];
    }

    const messages = validators
      ?.map((validator) => errorMessages[validator])
      ?.filter((message) => message);

    return messages;
  }

  const handleValueChange = (value, setAttr) => {
    setAttr(value);
  };

  useEffect(() => {
    ValidatorForm.addValidationRule("isAadhar", (value) => {
      return /^\d{12}$/.test(value);
    });

    return () => {
      ValidatorForm.removeValidationRule("isAadhar");
    };
  }, []);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Grid spacing={2} style={{ margin: "20vh" }}>
        <ValidatorForm onSubmit={handleSubmit} onError={null}>
          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {object?.map((item, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  type={item.type}
                  name={item?.title}
                  label={item?.title}
                  variant="outlined"
                  fullWidth
                  value={item?.attr}
                  onChange={(e) =>
                    handleValueChange(e.target.value, item?.setAttr)
                  }
                  validators={item?.validators || []}
                  errorMessages={
                    item?.validators ? getErrorMessages(item?.validators) : []
                  }
                />
              </Grid>
            ))}
          </Grid>
        </ValidatorForm>
      </Grid>
    </Dialog>
  );
}

export default ValidatorFormDialog;
