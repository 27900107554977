import { create } from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      city: null,
      state: null,
      country: null,

      fetchLocationData: async () => {
        try {
          const response = await fetch(
            "https://jsonplaceholder.typicode.com/users"
          );
          const data = await response.json();

          const locationData = {
            city: data[0].address.city,
            state: data[0].address.street,
            country: "USA",
          };

          set(locationData);
        } catch (error) {
          console.error("Error", error);
        }
      },
    }),
    {
      name: "location-store",
      getStorage: () => sessionStorage,
    }
  )
);

useStore.getState().fetchLocationData();

export default useStore;
