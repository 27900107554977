import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import DrawerPage from "./DrawerPage";
import routes from "./navigationConfig";
import { UserProvider, useUserContext } from "./Contexts/UserContext";
import Login from "./Views/LoginPage/LoginPage"; 

function App() {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerOpen = () => setDrawerOpen(true);
  const handleDrawerClose = () => setDrawerOpen(false);

  return (
    <UserProvider>
      <Router>
        <MainRoutes
          drawerOpen={drawerOpen}
          handleDrawerClose={handleDrawerClose}
          handleDrawerOpen={handleDrawerOpen}
        />
      </Router>
    </UserProvider>
  );
}

const MainRoutes = ({ drawerOpen, handleDrawerClose, handleDrawerOpen }) => {
  const { user } = useUserContext();


  return (
    <Routes>
      {/* {!user ? (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </>
      ) : ( */}
        <Route
          path="/"
          element={
            <DrawerPage
              drawerOpen={drawerOpen}
              handleDrawerClose={handleDrawerClose}
              handleDrawerOpen={handleDrawerOpen}
            />
          }
        >
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Route>
      {/* )} */}
    </Routes>
  );
};

export default App;
