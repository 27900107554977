import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import ValidatorFormDialog from "./ValidatorFormDialog";

function TextFormValidator() {
  const [open, setOpen] = useState(false);

  const [text, setText] = useState();
  const [number, setNumber] = useState();
  const [newValue, setNewValue] = useState();

  const object = [
    {
      attr: text,
      setAttr: setText,
      title: "Text",
      type: "text",
      validators: ["isAadhar"],
    },
    { attr: number, setAttr: setNumber, title: "Number", type: "number" },
    { attr: newValue, setAttr: setNewValue, title: "Value", type: "text" },
  ];

  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <Grid>
      <Button
        onClick={() => {
          setOpen(true);
        }}
      >
        Open Form
      </Button>
      <ValidatorFormDialog
        open={open}
        setOpen={setOpen}
        handleSubmit={handleSubmit}
        object={object}
      />
    </Grid>
  );
}

export default TextFormValidator;
