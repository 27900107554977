import React from 'react';
import styled from 'styled-components';

// Styled component for the button
const Button = styled.button`
  background-color: #00bfa6;
  padding: 14px 40px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 2px;
  cursor: pointer;
  border-radius: 10px;
  border: 2px dashed #00bfa6;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: 0.4s;
  position: relative; /* For pseudo-elements */

  &::after {
    content: 'Hovered!';
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #00bfa6;
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  }

  &:hover {
    background-color: #fff;
    color: #00bfa6;
    border: 2px dashed #00bfa6;

    &::after {
    
    }
  }

  &:active {
    background-color: #87dbd0;
  }
`;

const HoverButton = () => {
  return (
    <Button>
      Hover me 6
    </Button>
  );
};

export default HoverButton;
