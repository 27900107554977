import React from "react";
import { Typography } from "@mui/material";
import useStore from "../ReactComponents/Stores/useStores";

const AboutPage = () => {
  const { city, state, country } = useStore();

  return (
    <div>
      <Typography variant="h4">About Page</Typography>
      <Typography variant="body1">City: {city}</Typography>
      <Typography variant="body1">State: {state}</Typography>
      <Typography variant="body1">Country: {country}</Typography>
    </div>
  );
};

export default AboutPage;
