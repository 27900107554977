import React, { useState } from 'react';

const Navbar = ({ items, color }) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const handleMouseEnter = (index) => {
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
    setActiveSubmenu(null);
  };

  const handleSubmenuMouseEnter = (index) => {
    setActiveSubmenu(index);
  };

  const handleSubmenuMouseLeave = () => {
    setActiveSubmenu(null);
  };

  const styles = {
    navbar: {
      backgroundColor: color || '#333', 
      padding: '10px 20px',
      display: 'flex',
      alignItems: 'center',
      width:"450px"
    },
    list: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      display: 'flex',
    },
    item: {
      margin: '0 15px',
      position: 'relative',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      padding: '8px 12px',
      backgroundColor: color || '#333', 
      color: 'white',
      textDecoration: 'none',
      transition: 'background-color 0.3s',
    },
    submenu: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      position: 'absolute',
      top: '100%',
      left: 0,
      backgroundColor: color || '#333',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      zIndex: 1,
      minWidth: '150px',
    },
    nestedItem: {
      margin: 0,
      position: 'relative',
    },
    nestedSubmenu: {
      listStyleType: 'none',
      padding: 0,
      margin: 0,
      position: 'absolute',
      top: 0,
      left: '100%',
      backgroundColor: color || '#333',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      zIndex: 1,
      minWidth: '150px',
    },
    arrow: {
      marginLeft: '8px',
      cursor: 'pointer',
      color: 'white',
    },
  };

  return (
    <nav style={styles.navbar}>
      <ul style={styles.list}>
        {items.map((item, index) => (
          <li
            key={index}
            style={styles.item}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <a href={item.link} style={styles.link}>
              {item.title}
              {item.subsections && (
                <span style={styles.arrow}>
                  {activeMenu === index ? '▼' : '▶'}
                </span>
              )}
            </a>
            {activeMenu === index && item.subsections && (
              <ul
                style={styles.submenu}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                {item.subsections.map((subItem, subIndex) => (
                  <li
                    key={subIndex}
                    style={styles.nestedItem}
                    onMouseEnter={() => handleSubmenuMouseEnter(subIndex)}
                    onMouseLeave={handleSubmenuMouseLeave}
                  >
                    <a href={subItem.link} style={styles.link}>
                      {subItem.title}
                      {subItem.subsections && (
                        <span style={styles.arrow}>
                          {activeSubmenu === subIndex ? '▼' : '▶'}
                        </span>
                      )}
                    </a>
                    {activeSubmenu === subIndex && subItem.subsections && (
                      <ul style={{ ...styles.nestedSubmenu, display: 'block' }}>
                        {subItem.subsections.map((nestedItem, nestedIndex) => (
                          <li key={nestedIndex} style={styles.nestedItem}>
                            <a href={nestedItem.link} style={styles.link}>
                              {nestedItem.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default Navbar;
